;
(function ($) {
	var $jobForm = $('#create-tender, #create-response');
	if ($jobForm.get(0) !== undefined) {
		var countIndustries = 1;

		$('#dates_type').on('change', function () {
			if ($(this).val() == 'starting_from') {
				$('.start_date_container').removeClass('hide');
			} else if (!$('.start_date_container').hasClass('hide')) {
				$('.start_date_container').addClass('hide');
			}
		});

		function addIndustry() {
			var tempCount; 
			var $link = $(this), $container = $(this).closest('div');

			//$container.find('#industries-1').attr('id','#industries-'+ ++countIndustries);
			
			if (countIndustries >= 3) {
				
				return false;
			}
			countIndustries++;
			tempCount = countIndustries;
			if(countIndustries == 2){

				$('#industries-first-field').after('<div id="industries-second-field" class="col-xs-12 col-sm-12"> <div class="form-group cat_dd col-xs-6"><label for="category" class="sr-only">Category</label><div class="input-group "><span class="input-group-addon">Category</span><input id="industries_'+countIndustries+'_front" data-output="'+countIndustries+'" class="form-control" type="text" placeholder="Enter a Category" value=""> <input id="industries_'+countIndustries+'_back" name="industries[]" class="form-control" type="hidden" value=""></div></div> </div>');
			}
			else if (countIndustries == 3){
				$('#add-industry').remove();
				$('#industries-second-field').after('<div class="col-xs-12 col-sm-12"> <div class="form-group cat_dd col-xs-6"><label for="category" class="sr-only">Category</label><div class="input-group "><span class="input-group-addon">Category</span><input id="industries_'+countIndustries+'_front" data-output="'+countIndustries+'" class="form-control" type="text" placeholder="Enter a Category" value=""> <input id="industries_'+countIndustries+'_back" name="industries[]" class="form-control" type="hidden" value=""></div></div> </div>');
			}
			
			 $link.on('click', addIndustry);
			 if (window.availableTagsForTypeahead !== undefined) {
			var $companies = $('#create-tender');
			var $cat_input  = $companies.find('form').find('#industries_'+countIndustries+'_front');
			var matchid = '';

			function substringMatcher(strs) {
				return function findMatches(q, cb) {
					
					var matches, substrRegex;

					// an array that will be populated with substring matches
					matches = [];

					// regex used to determine if a string contains the substring `q`
					substrRegex = new RegExp(q, 'i');

					// iterate through the pool of strings and for any string that
					// contains the substring `q`, add it to the `matches` array
					$.each(strs, function (i, str) {
						if (substrRegex.test(str)) {
							matches.push(str);

						}
					});

					cb(matches);
				};
			}

			$('#industries_'+countIndustries+'_front').typeahead({
				hint:      true,
				highlight: true,
				minLength: 1
			}, {
				name:   'tags',
				source: substringMatcher(availableTagsForTypeahead)
			}).on('typeahead:selected', function(event, data){    
				for(var key in availableTagsForTypeahead) {
					if(availableTagsForTypeahead[key] === data) {
						$('#industries_'+tempCount+'_back').attr('value', key);  
						console.log(tempCount);
					}
				}
			});

			$companies.find('.company').on('mouseover', function () {
				var $img = $(this).find('.location > img');
				$img.prop('src', '/img/profiles/location-hover.png')
			}).on('mouseout', function () {
				var $img = $(this).find('.location > img');
				$img.prop('src', '/img/profiles/location.png')
			});
		 
		}
			return false;
		}

		var $tenders = $('#tenders');

		$tenders.find('form a#add-industry').on('click', addIndustry);

		$jobForm.find('form #save-draft').on('click', function () {
			var $form = $(this).closest('form');
			$form.append('<input type="hidden" name="draft" value="yes">');
			$form.submit();
		});

		var $addFileButton = $('#add-file');
		$addFileButton.on('click', function () {
			var $realInput      = $('<input type="file" class="hide hidden-hide-file" name="attachments[]">');
			var $filesContainer = $('#files');

			$filesContainer.closest('div').append($realInput);
			$realInput.on('change', function () {
				var fileName = $(this).val().split('\\').last();

				$filesContainer.append($('<li>' + fileName + '</li>'))
			});
			$realInput.click();
		});
	}
	  $(document).ready(function () {
		  if (window.availableTendersForTypeahead !== undefined) {
			var $tenders = $('#tenders');
			var $tenders_input  = $tenders.find('.search-companies').find('#services');
			if($tenders_input.length <= 0){
				var $tenders_input  = $tenders.find('.top-guest').find('#services');
			}
			function substringMatcher(strs) {
				return function findMatches(q, cb) {
					var matches, substrRegex;

					// an array that will be populated with substring matches
					matches = [];

					// regex used to determine if a string contains the substring `q`
					substrRegex = new RegExp(q, 'i');

					// iterate through the pool of strings and for any string that
					// contains the substring `q`, add it to the `matches` array
					$.each(strs, function (i, str) {
						if (substrRegex.test(str)) {
							matches.push(str);
						}
					});

					cb(matches);
				};
			}

			$tenders_input.typeahead({
				hint:      true,
				highlight: true,
				minLength: 1
			}, {
				name:   'Tenders',
				source: substringMatcher(availableTendersForTypeahead)
			});
		 
		}
		var $companies = $('#tenders');
		var $services_loc  = $companies.find('.search-companies').find('#location');
		if(!$services_loc.length){
			var $services_loc  = $companies.find('.top-guest').find('#location');
		}

		var options = {
			componentRestrictions: {country: "uk"}
		};

		if( window.google !== undefined && window.google.maps !== undefined ){
			autocomplete = new window.google.maps.places.Autocomplete($services_loc.get(0), options);
		}
	});
})(jQuery);
