;(function ($) {
	//close messages on company profile page
	$("#close-message").fadeTo(2000, 500).slideUp(500, function(){
		$("#close-message").alert('close');
	});

	function PlaceChanged() {
		var place      = autocomplete.getPlace(),
		components = place.address_components,
		location   = [],
		ignore     = [
			'United Kingdom'
		];

		for (var i in components) {
			if (components[i].long_name !== undefined
				&& location.indexOf(components[i].long_name) === -1
				&& ignore.indexOf(components[i].long_name) === -1 ){
				location.push(components[i].long_name);
			}
		}
		$input.val(location.join(', '))
	}

	$('form').on( "click", ".cat_dd_preview", function() {
		var cat_container = $(this).parent().find('.cat_dd_container');

		if($(cat_container).is(":visible")) {
			$(this).find('.dd_icon').removeClass('active');
		} else {
			$(this).find('.dd_icon').addClass('active');
		}
		$(cat_container).slideToggle();   
	});

	$('form').on( "click", ".cat_dd_container li a", function(e) {
		var cat_val = $(this).closest('li').val();
		var cat_container = $(this).closest('.cat_dd_container');
		$(cat_container).find('li').removeClass('active');
		$(this).parent().addClass('active').parent().closest('li').addClass('active');
		$(cat_container).closest('.input-group').find('input').val(cat_val);
		var str =  $(this).parent().attr('data-cat-name');
		var strleng = str.length; 
		if(strleng >= 45){
			str = str.substring(0,45).trim()+'...';
		}
		var preview_span = $(cat_container).parent().find('.cat_dd_preview');
		$(preview_span).find('.text').text(str);
		$(preview_span).find('.dd_icon').removeClass('active');
		$(cat_container).hide();
	});

	$(document).click(function(event) { 
		if(!$(event.target).closest('.cat_dd').length) {
			if($('.cat_dd_container').is(":visible")) {
				$('.cat_dd_container').hide();
				$('.cat_dd_preview .dd_icon').removeClass('active');
			}
		}        
	})

	$(document).ready(function () {
		$('.cat_dd_container li').each(function(){
		   if($(this).find('li').length > 0 ){
			$(this).append('<span class="dd_iconside"></span>');
		}
	});

	if (window.availableServicesForTypeahead !== undefined) {
		var $companies = $('#companies');
		var $services  = $companies.find('.search-companies').find('#services');
		if(!$services.length){
			var $services  = $companies.find('.top-guest').find('#services');
		}

		function substringMatcher(strs) {
			return function findMatches(q, cb) {
				var matches, substrRegex;

					// an array that will be populated with substring matches
					matches = [];

					// regex used to determine if a string contains the substring `q`
					substrRegex = new RegExp(q, 'i');

					// iterate through the pool of strings and for any string that
					// contains the substring `q`, add it to the `matches` array
					$.each(strs, function (i, str) {
						if (substrRegex.test(str)) {
							matches.push(str);
						}
					});

					cb(matches);
				};
			}

			$services.typeahead({
				hint:      true,
				highlight: true,
				minLength: 1
			}, {
				name:   'services',
				source: substringMatcher(availableServicesForTypeahead)
			});

			$companies.find('.company').on('mouseover', function () {
				var $img = $(this).find('.location > img');
				$img.prop('src', '/img/profiles/location-hover.png')
			}).on('mouseout', function () {
				var $img = $(this).find('.location > img');
				$img.prop('src', '/img/profiles/location.png')
			});

		}

		var $companies = $('#companies');
		var $services_loc  = $companies.find('.search-companies').find('#location');
		if(!$services_loc.length){
			var $services_loc  = $companies.find('.top-guest').find('#location');
		}

		var options      = {
			componentRestrictions: {country: "uk"}
		};

		if( window.google !== undefined && window.google.maps !== undefined ){
			autocomplete = new window.google.maps.places.Autocomplete($services_loc.get(0), options);
			google.maps.event.addListener(autocomplete, 'place_changed', PlaceChanged);
		}

		if (window.availableTagsForTypeahead !== undefined) {
			var $companies = $('#create-tender');
			var $cat_input  = $companies.find('form').find('#industries_1_front');
			var matchid = '';

			function substringMatcher(strs) {
				return function findMatches(q, cb) {

					var matches, substrRegex;

					// an array that will be populated with substring matches
					matches = [];

					// regex used to determine if a string contains the substring `q`
					substrRegex = new RegExp(q, 'i');

					// iterate through the pool of strings and for any string that
					// contains the substring `q`, add it to the `matches` array
					$.each(strs, function (i, str) {
						if (substrRegex.test(str)) {
							matches.push(str);

						}
					});

					cb(matches);
				};
			}

			$cat_input.typeahead({
				hint:      true,
				highlight: true,
				minLength: 1
			}, {
				name:   'tags',
				source: substringMatcher(availableTagsForTypeahead)
			}).on('typeahead:selected', function(event, data){    
				for(var key in availableTagsForTypeahead) {
					if(availableTagsForTypeahead[key] === data) {
						$('#industries_1_back').attr('value', key);  
					}
				}
			});

			$companies.find('.company').on('mouseover', function () {
				var $img = $(this).find('.location > img');
				$img.prop('src', '/img/profiles/location-hover.png')
			}).on('mouseout', function () {
				var $img = $(this).find('.location > img');
				$img.prop('src', '/img/profiles/location.png')
			});

		}
		if (window.availableTagsForTypeahead !== undefined) {
			var $companies = $('#create-tender');
			var $cat_input  = $companies.find('form').find('#industries_2_front');
			var matchid = '';

			function substringMatcher(strs) {
				return function findMatches(q, cb) {

					var matches, substrRegex;

					// an array that will be populated with substring matches
					matches = [];

					// regex used to determine if a string contains the substring `q`
					substrRegex = new RegExp(q, 'i');

					// iterate through the pool of strings and for any string that
					// contains the substring `q`, add it to the `matches` array
					$.each(strs, function (i, str) {
						if (substrRegex.test(str)) {
							matches.push(str);

						}
					});

					cb(matches);
				};
			}

			$cat_input.typeahead({
				hint:      true,
				highlight: true,
				minLength: 1
			}, {
				name:   'tags',
				source: substringMatcher(availableTagsForTypeahead)
			}).on('typeahead:selected', function(event, data){    
				for(var key in availableTagsForTypeahead) {
					if(availableTagsForTypeahead[key] === data) {
						$('#industries_1_back').attr('value', key);  
					}
				}
			});

			$companies.find('.company').on('mouseover', function () {
				var $img = $(this).find('.location > img');
				$img.prop('src', '/img/profiles/location-hover.png')
			}).on('mouseout', function () {
				var $img = $(this).find('.location > img');
				$img.prop('src', '/img/profiles/location.png')
			});

		}
		if (window.availableTagsForTypeahead !== undefined) {
			var $companies = $('#create-tender');
			var $cat_input  = $companies.find('form').find('#industries_3_front');
			var matchid = '';

			function substringMatcher(strs) {
				return function findMatches(q, cb) {

					var matches, substrRegex;

					// an array that will be populated with substring matches
					matches = [];

					// regex used to determine if a string contains the substring `q`
					substrRegex = new RegExp(q, 'i');

					// iterate through the pool of strings and for any string that
					// contains the substring `q`, add it to the `matches` array
					$.each(strs, function (i, str) {
						if (substrRegex.test(str)) {
							matches.push(str);

						}
					});

					cb(matches);
				};
			}

			$cat_input.typeahead({
				hint:      true,
				highlight: true,
				minLength: 1
			}, {
				name:   'tags',
				source: substringMatcher(availableTagsForTypeahead)
			}).on('typeahead:selected', function(event, data){    
				for(var key in availableTagsForTypeahead) {
					if(availableTagsForTypeahead[key] === data) {
						$('#industries_1_back').attr('value', key);  
					}
				}
			});

			$companies.find('.company').on('mouseover', function () {
				var $img = $(this).find('.location > img');
				$img.prop('src', '/img/profiles/location-hover.png')
			}).on('mouseout', function () {
				var $img = $(this).find('.location > img');
				$img.prop('src', '/img/profiles/location.png')
			});

		}

	});
})(jQuery);
